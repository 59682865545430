module.exports = [{
      plugin: require('../node_modules/@petlabco/builder-checkout-upsell-integration/gatsby-browser.js'),
      options: {"plugins":[],"builderApiKey":"5220c7b51d674fc2bcfcb2c618883447","upsellApiUrl":"https://cdn.builder.io/api/v2/content/upsell","upsellSelectorApiUrl":"https://cdn.builder.io/api/v2/content/upsell-selector","checkoutStoreName":"ukamplify.myshopify.com","checkoutApiUrlV2":"https://checkout-api.petlabco.co.uk","checkoutApiKeyV2":"QBVLhipCwp8D36HrYHEVK2XeVcNytLCBaBofHAbr","forceCheckoutV2":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XDGQT2","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MMRJ7684","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
